<script setup lang="ts">
import type { HTMLAttributes } from "vue"

const props = defineProps<{
  class?: HTMLAttributes["class"]
}>()
</script>

<template>
  <div :class="cn('mb-6 flex flex-col gap-y-1.5 pt-8 text-start', props.class)">
    <slot />
  </div>
</template>
